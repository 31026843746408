<template>
  <div class="calendar vl-parent">
    <loading v-model:active="isLoading" :is-full-page="false" />

    <div class="filters py-2">
      <span class="me-2">Vendor:</span>
      <select class="col-md-6" v-model="vendor_id" @change="onChangeVendor">
        <option value="">View All</option>
        <option v-for="company in companies" :key="company.id" :value="company.id">{{ company.name }}</option>
      </select>
 
      <span class="mx-3">Installation:</span>
      <input v-model="installation" @change="handleSwitchDivision(1361)" type="checkbox" checked id="installation"
        class="installation">
      <label for="installation"></label>

      <span class="mx-3">Service Order:</span>
      <input v-model="serviceorder" @change="handleSwitchDivision(1329)" type="checkbox" checked id="service-order"
        class="service-order">
      <label for="service-order"></label>
    </div>

    <div class="filters py-2">
      <span class="me-2">Asigned to:</span>
      <select class="col-md-6" v-model="selectedUserId" @change="onChangeVendor">
        <option value="">View All</option>
        <option v-for="user in users" :key="user.id" :value="user.id">
          {{ user.name }}
        </option>
      </select>
    </div>

    <fullCalendar
      ref="fullCalendar"
      :options="calendarOptions"
    ></fullCalendar>

    <div v-if="!isLoading && this.calendarOptions.resources.length == 0" class="alert warning">
      Warning: This vendor doesn't have any technicians. You can add a technician to this vendor
      <a target="_blank" :href="`${ownerUrl}/Vendor/${vendor_id}/Contacts`">here</a>.
    </div>
  </div>
</template>

<script>
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/css/index.css';
  import axios from 'axios';
  import FullCalendar from '@fullcalendar/vue3'
  import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
  import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
  import dayGridPlugin from '@fullcalendar/daygrid'
  import 'bootstrap-vue/dist/bootstrap-vue.css';
  import 'bootstrap/dist/css/bootstrap.css';

  export default {
    components: {
      Loading,
      FullCalendar // make the <FullCalendar> tag available
    },
    inject: ['$emitter'],
    props: ['selfPerformingId'],
    data() {
      return {
        ownerUrl: process.env.VUE_APP_OWNER_URL,
        isLoading: true,
        calendarOptions: {
          schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
          plugins: [resourceTimelinePlugin, interactionPlugin, dayGridPlugin],
          initialView: 'resourceTimelineDay',
          dayMaxEvents: 4, // Will show "more" link if too many events
          dayMaxEventRows: true,
          contentHeight: 'auto',
          editable: true,
          droppable: true,
          resourceOrder: 'title,id',
          resources: [],
          //events: this.getEvents,
          eventDisplay: 'block',
          eventReceive: this.handleEventReceive,
          //eventResize: this.handleEventResize,
          eventChange: this.handleEventChange,
          eventClick: this.handleEventClick,
          headerToolbar: {
            start: 'title', // will normally be on the left. if RTL, will be on the right
            center: '',
            end: 'resourceTimelineDay resourceTimelineWeek dayGridMonth prev,next' // will normally be on the right. if RTL, will be on the left
          },
          datesSet: (dateInfo) => {
            this.getEvents(dateInfo)
          }
        },
        eventsFetchInfo: {},
        companies: [],
        vendor_id: '',
        lastItemDroped: '',
        installation: true,
        serviceorder: true,
        firstLoad: true,
        users : [],
        selectedUserId: '',
      }
    },
    created() {
      
    },
    mounted() {
      this.getResources();
      this.getVendors();
      this.getUsers();
      // this.vendor_id = this.selfPerformingId
    },
    methods: {
      getResources() {
        this.isLoading = true;
        let data = {
          vendor_id : this.vendor_id,
          user_id: this.selectedUserId,
        };
        
        axios.get('/api/v2/scheduler/resources', { params: data })
        .then((response) => {
          this.calendarOptions.resources = response.data.data;
          this.isLoading = false;

          this.getEvents(this.eventsFetchInfo)

          this.setDraggable()
        }).catch((error) => {
          console.log(error)
        });
      },
      getEvents(fetchInfo) {
        this.isLoading = true;
        this.eventsFetchInfo = fetchInfo;

        //let calendarApi = this.$refs.fullCalendar.getApi();
        let data = {
          vendor_id: this.vendor_id,
          start: fetchInfo.start,
          end: fetchInfo.end,
          timeZone: fetchInfo.timeZone,
          division: [],
          user_id: this.selectedUserId
        };

        if (this.installation) {
          data.division.push(1361)
        }

        if (this.serviceorder) {
          data.division.push(1329)
        }

        axios.get('/api/v2/scheduler/events', { params: data })
        .then((response) => {
          this.calendarOptions.events = response.data.data;
          this.isLoading = false;

        }).catch((error) => {
          console.log(error)
        });
      },
      getVendors() {
        axios.get('/api/companies?type=2&vendor_status_id=1003&limit=200')
        .then((response) => {
          this.companies = response.data.data;
          this.isLoading = false;
        }).catch((error) => {
          console.log(error)
        });
      },
      getUsers() {
        axios.get('/api/users')
        .then((response) => {
          this.users = response.data.data;
          this.isLoading = false;
        }).catch((error) => {
          console.log(error)
        });
      },
      setDraggable() {
        let externalEvents = document.getElementById('external-events');
        new Draggable(externalEvents, {
          itemSelector: '.external-event',
          eventData: function (eventEl) {
            return {
              workOrderId: eventEl.dataset.workOrderId,
              title: eventEl.innerText.trim(),
              color: eventEl.dataset.color,
              duration: '04:00:00' // Duración de 1 hora para cada evento arrastrado
            };
          }
        });
      },
      handleEventReceive(payload) {
        // escape if work order is duplicated
        if (this.lastItemDroped === payload.event.extendedProps.workOrderId) {
          return;
        }

        let data = {
          work_order_id: payload.event.extendedProps.workOrderId,
          resource_id: payload.event.getResources()[0].id,
          title: payload.event.title,
          start: payload.event.start,
          end: payload.event.end,
        };

        this.lastItemDroped = payload.event.extendedProps.workOrderId;
        this.createEvent(data);
      },
      handleEventResize(payload) {
        let data = {
          resource_id: payload.event.getResources()[0].id,
          start: payload.event.start,
          end: payload.event.end,
        };

        axios.put('/api/v2/scheduler/events/' + payload.event.id, data)
        .then(() => {

        }).catch((error) => {
          console.log(error)
        });
      },
      handleEventChange(payload) {
        let data = {};

        if (payload?.event?.id > 0) {
          data = {
            resource_id: payload.event.getResources()[0].id,
            start: payload.event.start,
            end: payload.event.end,
          }
          this.updateEvent(payload.event.id, data);
        } else {
          data = {
            work_order_id: payload.event.extendedProps.workOrderId,
            resource_id: payload.event.getResources()[0].id,
            title: payload.event.title,
            start: payload.event.start,
            end: payload.event.end,
          };
          this.createEvent(data);
        }
      },
      handleEventClick(event) {
        if (event.event.url) {
          event.jsEvent.preventDefault();
          window.open(event.event.url, "_blank");
        }
      },
      createEvent(data) {
        axios.post('/api/v2/scheduler/events', data).then(() => {
          this.getEvents(this.eventsFetchInfo);
          this.$emitter.emit('onEventReceived');
        }).catch((error) => {
          console.log(error)
        });
      },
      updateEvent(id, data) {
        axios.put('/api/v2/scheduler/events/' + id, data).then(() => {
          this.getEvents(this.eventsFetchInfo);
        }).catch((error) => {
          console.log(error)
        });
      },
      onChangeVendor() {
        this.firstLoad = false;
        this.getResources();
      },
      handleDatesSet() {
        
      },
      handleSwitchDivision() {
        this.firstLoad = false;
        this.getEvents(this.eventsFetchInfo);
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  .container {
    max-width: 100% !important;
  }

  .calendar {
    min-height: 600px;
    --fc-border-color: #E4E6EB;
  }

  .filters {
    text-align: left;
    display: flex;
    align-items: center;
  }

  select {
    min-width: 200px;
  }

  .fc .fc-button-primary {
    text-transform: capitalize !important;
  }

  .fc th {
    font-family: Nunito;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    text-align: center;
    color: #000000;
  }

  .fc td {
    font-family: Nunito;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #000000;
  }

  .fc-timeline-event .fc-event-time,
  .fc-timeline-event .fc-event-title {
    padding: 4px 10px !important;
    white-space: normal !important;
  }

  .fc-daygrid-event {
    white-space: normal !important;
  }

  .fc-event-time {
    min-width: 30px;
  }

  /** SWITCH Styles */

  .filters input[type="checkbox"] {
    width: 0;
    height: 0;
    visibility: hidden;
  }

  .filters label {
    display: inline-block;
    width: 50px;
    height: 20px;
    background-color: #b8b8b8;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
    transition: 0.5s;
    box-shadow: 0 0 20px #477a8550;
  }


  .filters label::after {
    content: "";
    width: 16px;
    height: 16px;
    background-color: #e8f5f7;
    position: absolute;
    border-radius: 50%;
    top: 2px;
    left: 2px;
    transition: 0.5s;
  }

  input:checked+label:after {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }

  input:checked+label {
    background-color: #0066ff;
  }

  input.installation:checked+label {
    background-color: #E65100;
  }

  input.service-order:checked+label {
    background-color: #001F3D;
  }

  label:active:after {
    width: 50px;
  }

  .alert {
    padding: 8px;
    margin-top: 20px;
    font-size: 16px;
    border: 1px solid transparent
  }

  .warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
  }
</style>
